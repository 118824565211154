@keyframes rotateBorder {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


  .custom-card {
    background: #0693e3;
    color: black;
    transition: background-color 0.2s;
    height: 300px;
  }
  
  .custom-card:hover {
    background-color: #0693e3;
    color: white;
  }


  /* ---- reset ---- */
body {
  margin: 0;
  font: normal 75% Arial, Helvetica, sans-serif;
}

canvas {
  display: block;
;
}

/* ---- particles.js container ---- */
#particles-js {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #b61924; /* Fallback color */
  background-image: url(""); /* Fallback background image */
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 50% 50%; */

  
}

/* ---- stats.js ---- */
.count-particles {
  background: #000022;
  position: relative;
  top: 0px;
  left: 0;
  width: 80px;
  color: #13e8e9;
  font-size: 0.8em;
  text-align: left;
  text-indent: 4px;
  line-height: 14px;
  padding-bottom: 2px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
}

.js-count-particles {
  font-size: 1.1em;
}

#stats,
.count-particles {
  -webkit-user-select: none;
  margin-top: 5px;
  margin-left: 5px;
}

#stats {
  border-radius: 5px 5px 0 0;
  overflow: hidden;
}

.count-particles {
  border-radius: 0 0 10px 10px;
}


@keyframes fadeRight {
  from {
    opacity: 0;
    transform: translateX(-500px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-right {
  animation: fadeRight 3s ease forwards;
}

/*Blog */
/* CSS */
@media (max-width: 600px) {
  .responsive-paper {
    margin: 20px;
  }
}









/* -------------------------------hexagon---------------------------------- */

.shape-outer {
  display: flex;
  flex-shrink: 0;
  height: calc(90px + 3vw);
  width: calc(100px + 3vw);

  background-image: linear-gradient(to bottom right, #0693e3, #939595);
  position: relative;

}

.shape-inner {
  height: calc(70px + 2vw);
  width: calc(80px + 2vw);
  background: white;
  background-size: cover;
  margin: auto;
  position: relative; 
}

.hexagon {
  -webkit-clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}

.hexagon-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
}
.text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(117, 117, 117);

}

@media screen and (min-width: 480px) {
 .img-height{
  height: 275px;
 }
 .Font-size{
  font-size: 20px;
 }
  
}